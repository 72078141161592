<!-- Created by henian.xu on 2017/10/22. -->
<template>
  <Page>
    <div class="header">
      <div class="ctrl-left">
        <a
          href="javascript:history.go(-1);"
          class="btn"
        ><i class="f-icon">&#xf011;</i></a>
      </div>
      <div class="label">
        {{ this.$route.meta.title }}
      </div>
      <div class="ctrl-right">
        <a
          href="javascript:;"
          class="btn"
        ><i class="f-icon">&#xf08c;</i></a>
      </div>
    </div>
    <div class="second-header">
      second-header
    </div>
    <container ref="container">
      <template v-if="item.meta">
        <linker
          v-for="(item,index) in $router.options.routes"
          :to="item.path"
          :key="index"
          tag="div"
          class="pa-a"
        >
          {{ item.meta.title }}
        </linker>
      </template>
    </container>
    <div class="second-footer">
      second-footer
    </div>
    <div class="footer">
      <div class="btn">
        btn
      </div>
    </div>
  </Page>
</template>

<script>
// import pageMixin from '@/mixins/layout/page';

export default {
  name: 'TPL',
  // mixins: [pageMixin],
  data() {
    return {};
  },
};
</script>

<style lang="scss">
</style>
